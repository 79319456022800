import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e783d8da = () => interopDefault(import('../src/pages/campaign.vue' /* webpackChunkName: "pages/campaign" */))
const _b9c2583a = () => interopDefault(import('../src/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _91f0d078 = () => interopDefault(import('../src/pages/learn-more/index.vue' /* webpackChunkName: "pages/learn-more/index" */))
const _5c794514 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0077f060 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _5cc5c25d = () => interopDefault(import('../src/pages/offers/index.vue' /* webpackChunkName: "pages/offers/index" */))
const _7b42773e = () => interopDefault(import('../src/pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _d4f12d56 = () => interopDefault(import('../src/pages/portfolio.vue' /* webpackChunkName: "pages/portfolio" */))
const _26d2dc18 = () => interopDefault(import('../src/pages/portfolio/index.vue' /* webpackChunkName: "pages/portfolio/index" */))
const _0ef77a16 = () => interopDefault(import('../src/pages/portfolio/closed.vue' /* webpackChunkName: "pages/portfolio/closed" */))
const _e14db85e = () => interopDefault(import('../src/pages/portfolio/reservations.vue' /* webpackChunkName: "pages/portfolio/reservations" */))
const _0ec0c88e = () => interopDefault(import('../src/pages/portfolio/statements.vue' /* webpackChunkName: "pages/portfolio/statements" */))
const _647f1398 = () => interopDefault(import('../src/pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _79400479 = () => interopDefault(import('../src/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _1cace959 = () => interopDefault(import('../src/pages/promoReferral.vue' /* webpackChunkName: "pages/promoReferral" */))
const _66abb744 = () => interopDefault(import('../src/pages/signedin.vue' /* webpackChunkName: "pages/signedin" */))
const _99fb834a = () => interopDefault(import('../src/pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _c0896f4a = () => interopDefault(import('../src/pages/transactions/index.vue' /* webpackChunkName: "pages/transactions/index" */))
const _7b2ac91a = () => interopDefault(import('../src/pages/waiting.vue' /* webpackChunkName: "pages/waiting" */))
const _276f127b = () => interopDefault(import('../src/pages/withdrawal/index.vue' /* webpackChunkName: "pages/withdrawal/index" */))
const _081bba5f = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _fac71b02 = () => interopDefault(import('../src/pages/offers/cancel.vue' /* webpackChunkName: "pages/offers/cancel" */))
const _d13d471e = () => interopDefault(import('../src/pages/offers/closed.vue' /* webpackChunkName: "pages/offers/closed" */))
const _0b5c6929 = () => interopDefault(import('../src/pages/withdrawal/confirm.vue' /* webpackChunkName: "pages/withdrawal/confirm" */))
const _3da4608c = () => interopDefault(import('../src/pages/withdrawal/finish.vue' /* webpackChunkName: "pages/withdrawal/finish" */))
const _d8659586 = () => interopDefault(import('../src/pages/offers/terms/_id.vue' /* webpackChunkName: "pages/offers/terms/_id" */))
const _a6aa8ff6 = () => interopDefault(import('../src/pages/offers/_id.vue' /* webpackChunkName: "pages/offers/_id" */))
const _10933f56 = () => interopDefault(import('../src/pages/reservations/_id.vue' /* webpackChunkName: "pages/reservations/_id" */))
const _92963806 = () => interopDefault(import('../src/pages/signcontract/_id.vue' /* webpackChunkName: "pages/signcontract/_id" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campaign",
    component: _e783d8da,
    name: "campaign___pt-br"
  }, {
    path: "/faq",
    component: _b9c2583a,
    name: "faq___pt-br"
  }, {
    path: "/learn-more",
    component: _91f0d078,
    name: "learn-more___pt-br"
  }, {
    path: "/login",
    component: _5c794514,
    name: "login___pt-br"
  }, {
    path: "/maintenance",
    component: _0077f060,
    name: "maintenance___pt-br"
  }, {
    path: "/offers",
    component: _5cc5c25d,
    name: "offers___pt-br"
  }, {
    path: "/onboarding",
    component: _7b42773e,
    name: "onboarding___pt-br"
  }, {
    path: "/portfolio",
    component: _d4f12d56,
    children: [{
      path: "",
      component: _26d2dc18,
      name: "portfolio___pt-br"
    }, {
      path: "closed",
      component: _0ef77a16,
      name: "portfolio-closed___pt-br"
    }, {
      path: "reservations",
      component: _e14db85e,
      name: "portfolio-reservations___pt-br"
    }, {
      path: "statements",
      component: _0ec0c88e,
      name: "portfolio-statements___pt-br"
    }]
  }, {
    path: "/privacy",
    component: _647f1398,
    name: "privacy___pt-br"
  }, {
    path: "/profile",
    component: _79400479,
    name: "profile___pt-br"
  }, {
    path: "/promoReferral",
    component: _1cace959,
    name: "promoReferral___pt-br"
  }, {
    path: "/signedin",
    component: _66abb744,
    name: "signedin___pt-br"
  }, {
    path: "/signup",
    component: _99fb834a,
    name: "signup___pt-br"
  }, {
    path: "/transactions",
    component: _c0896f4a,
    name: "transactions___pt-br"
  }, {
    path: "/waiting",
    component: _7b2ac91a,
    name: "waiting___pt-br"
  }, {
    path: "/withdrawal",
    component: _276f127b,
    name: "withdrawal___pt-br"
  }, {
    path: "/en-us/",
    component: _081bba5f,
    name: "index___en-us"
  }, {
    path: "/en-us/campaign",
    component: _e783d8da,
    name: "campaign___en-us"
  }, {
    path: "/en-us/faq",
    component: _b9c2583a,
    name: "faq___en-us"
  }, {
    path: "/en-us/learn-more",
    component: _91f0d078,
    name: "learn-more___en-us"
  }, {
    path: "/en-us/login",
    component: _5c794514,
    name: "login___en-us"
  }, {
    path: "/en-us/maintenance",
    component: _0077f060,
    name: "maintenance___en-us"
  }, {
    path: "/en-us/offers",
    component: _5cc5c25d,
    name: "offers___en-us"
  }, {
    path: "/en-us/onboarding",
    component: _7b42773e,
    name: "onboarding___en-us"
  }, {
    path: "/en-us/portfolio",
    component: _d4f12d56,
    children: [{
      path: "",
      component: _26d2dc18,
      name: "portfolio___en-us"
    }, {
      path: "closed",
      component: _0ef77a16,
      name: "portfolio-closed___en-us"
    }, {
      path: "reservations",
      component: _e14db85e,
      name: "portfolio-reservations___en-us"
    }, {
      path: "statements",
      component: _0ec0c88e,
      name: "portfolio-statements___en-us"
    }]
  }, {
    path: "/en-us/privacy",
    component: _647f1398,
    name: "privacy___en-us"
  }, {
    path: "/en-us/profile",
    component: _79400479,
    name: "profile___en-us"
  }, {
    path: "/en-us/promoReferral",
    component: _1cace959,
    name: "promoReferral___en-us"
  }, {
    path: "/en-us/signedin",
    component: _66abb744,
    name: "signedin___en-us"
  }, {
    path: "/en-us/signup",
    component: _99fb834a,
    name: "signup___en-us"
  }, {
    path: "/en-us/transactions",
    component: _c0896f4a,
    name: "transactions___en-us"
  }, {
    path: "/en-us/waiting",
    component: _7b2ac91a,
    name: "waiting___en-us"
  }, {
    path: "/en-us/withdrawal",
    component: _276f127b,
    name: "withdrawal___en-us"
  }, {
    path: "/offers/cancel",
    component: _fac71b02,
    name: "offers-cancel___pt-br"
  }, {
    path: "/offers/closed",
    component: _d13d471e,
    name: "offers-closed___pt-br"
  }, {
    path: "/withdrawal/confirm",
    component: _0b5c6929,
    name: "withdrawal-confirm___pt-br"
  }, {
    path: "/withdrawal/finish",
    component: _3da4608c,
    name: "withdrawal-finish___pt-br"
  }, {
    path: "/en-us/offers/cancel",
    component: _fac71b02,
    name: "offers-cancel___en-us"
  }, {
    path: "/en-us/offers/closed",
    component: _d13d471e,
    name: "offers-closed___en-us"
  }, {
    path: "/en-us/withdrawal/confirm",
    component: _0b5c6929,
    name: "withdrawal-confirm___en-us"
  }, {
    path: "/en-us/withdrawal/finish",
    component: _3da4608c,
    name: "withdrawal-finish___en-us"
  }, {
    path: "/en-us/offers/terms/:id?",
    component: _d8659586,
    name: "offers-terms-id___en-us"
  }, {
    path: "/en-us/offers/:id",
    component: _a6aa8ff6,
    name: "offers-id___en-us"
  }, {
    path: "/en-us/reservations/:id?",
    component: _10933f56,
    name: "reservations-id___en-us"
  }, {
    path: "/en-us/signcontract/:id?",
    component: _92963806,
    name: "signcontract-id___en-us"
  }, {
    path: "/offers/terms/:id?",
    component: _d8659586,
    name: "offers-terms-id___pt-br"
  }, {
    path: "/offers/:id",
    component: _a6aa8ff6,
    name: "offers-id___pt-br"
  }, {
    path: "/reservations/:id?",
    component: _10933f56,
    name: "reservations-id___pt-br"
  }, {
    path: "/signcontract/:id?",
    component: _92963806,
    name: "signcontract-id___pt-br"
  }, {
    path: "/",
    component: _081bba5f,
    name: "index___pt-br"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}

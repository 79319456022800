import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faArrowDown as freeFasFaArrowDown,
  faCheck as freeFasFaCheck,
  faCoins as freeFasFaCoins,
  faEdit as freeFasFaEdit,
  faEye as freeFasFaEye,
  faHandshake as freeFasFaHandshake,
  faHome as freeFasFaHome,
  faSignInAlt as freeFasFaSignInAlt,
  faSignOutAlt as freeFasFaSignOutAlt,
  faSpinner as freeFasFaSpinner,
  faStore as freeFasFaStore,
  faTimes as freeFasFaTimes,
  faTrash as freeFasFaTrash,
  faUsers as freeFasFaUsers,
  faUser as freeFasFaUser,
  faChevronLeft as freeFasFaChevronLeft,
  faChevronRight as freeFasFaChevronRight,
  faChevronUp as freeFasFaChevronUp,
  faChevronDown as freeFasFaChevronDown,
  faBars as freeFasFaBars,
  faQuestionCircle as freeFasFaQuestionCircle
} from '@fortawesome/free-solid-svg-icons'

import {
  faFilePdf as freeFarFaFilePdf,
  faFileImage as freeFarFaFileImage,
  faFileWord as freeFarFaFileWord,
  faFilePowerpoint as freeFarFaFilePowerpoint,
  faFileExcel as freeFarFaFileExcel,
  faFile as freeFarFaFile,
  faCopy as freeFarFaCopy
} from '@fortawesome/free-regular-svg-icons'

library.add(
  freeFasFaArrowDown,
  freeFasFaCheck,
  freeFasFaCoins,
  freeFasFaEdit,
  freeFasFaEye,
  freeFasFaHandshake,
  freeFasFaHome,
  freeFasFaSignInAlt,
  freeFasFaSignOutAlt,
  freeFasFaSpinner,
  freeFasFaStore,
  freeFasFaTimes,
  freeFasFaTrash,
  freeFasFaUsers,
  freeFasFaUser,
  freeFasFaChevronLeft,
  freeFasFaChevronRight,
  freeFasFaChevronUp,
  freeFasFaChevronDown,
  freeFasFaBars,
  freeFasFaQuestionCircle,
  freeFarFaFilePdf,
  freeFarFaFileImage,
  freeFarFaFileWord,
  freeFarFaFilePowerpoint,
  freeFarFaFileExcel,
  freeFarFaFile,
  freeFarFaCopy
)

config.autoAddCss = false

Vue.component('fa', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)

import Vue from 'vue'
import { Line, Bar, Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

Vue.component('chart-line', {
  extends: Line,
  props: ['chartData', 'options'],
  mixins: [reactiveProp],
  mounted() {
    // @ts-ignore
    this.renderChart(this.chartData, this.options)
  },
})
Vue.component('chart-bar', {
  extends: Bar,
  props: ['chartData', 'options'],
  mixins: [reactiveProp],
  mounted() {
    // @ts-ignore
    this.renderChart(this.chartData, this.options)
  },
})
Vue.component('chart-doughnut', {
  extends: Doughnut,
  props: ['chartData', 'options'],
  mixins: [reactiveProp],
  mounted() {
    // @ts-ignore
    this.renderChart(this.chartData, this.options)
  },
})


























import { Vue, Component, Emit } from 'vue-property-decorator'
import BlkTitle from '~/components/BlkTitle.vue'

@Component({
  name: 'LandingNumbers',
  components: {
    BlkTitle
  }
})
export default class extends Vue {
  @Emit('closeModal')
  closeModal() {}
}

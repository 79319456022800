












































































import { Component, Vue, Emit } from 'vue-property-decorator'
import gql from 'graphql-tag'
import BlkLoaderOverlay from '~/components/BlkLoaderOverlay.vue'

interface LeadType {
  fullName: string
  email: string
  phoneNumber: string
}

@Component({
  name: 'BlkLeadForm',
  components: {
    BlkLoaderOverlay
  }
})
export default class extends Vue {
  lead: LeadType = {
    fullName: '',
    email: '',
    phoneNumber: ''
  }

  nameFocus: boolean = false
  emailFocus: boolean = false
  phoneFocus: boolean = false
  isLoading: boolean = false

  emailValid: any = null

  checkSize(input: string) {
    if (input.length > 0) {
      return true
    } else {
      return false
    }
  }

  checkEmail() {
    this.checkSize(this.lead.email)
      ? (this.emailFocus = true)
      : (this.emailFocus = false)

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    re.test(this.lead.email.toLowerCase())
      ? (this.emailValid = true)
      : (this.emailValid = false)
  }

  @Emit('formResult')
  formResult(result: boolean) {
    return result
  }

  @Emit('responseModal')
  responseModal() {}

  onSubmit() {
    this.isLoading = true
    this.$apollo
      .mutate({
        mutation: gql`
          mutation($lead: WaitlistInput!) {
            createWaiting(waitlistData: $lead) {
              waitlist {
                id
              }
            }
          }
        `,
        variables: {
          lead: this.lead
        }
      })
      .then(() => {
        this.formResult(true)
        this.isLoading = false
        this.responseModal()
      })
      .catch((err) => {
        this.isLoading = false
        if (err.message.includes('Tried to save duplicate unique keys')) {
          this.$bvToast.toast('Email já cadastrado!', {
            title: `Erro`,
            variant: 'danger',
            solid: true
          })
        } else {
          this.$bvToast.toast('Ocorreu um erro, tente novamente mais tarde.', {
            title: `Erro`,
            variant: 'danger',
            solid: true
          })
        }
      })
  }
}






















import { Vue, Component, Emit } from 'vue-property-decorator'
import BlkLeadForm from '~/components/BlkLeadForm.vue'
import BlkTitle from '~/components/BlkTitle.vue'
import BlkResponseModal from '~/components/BlkResponseModal.vue'

@Component({
  name: 'LandingNumbers',
  components: {
    BlkLeadForm,
    BlkTitle,
    BlkResponseModal
  }
})
export default class extends Vue {
  responseModal: boolean = false

  @Emit('closeModal')
  closeModal() {}
}

export default function(
  { graphQLErrors, networkError, operation, forward }: any,
  context: any
) {
  // check if the error is not authorized
  const isNotAuthorized: boolean =
    graphQLErrors.filter((el: any) => el.message.includes('Not authorized'))
      .length >= 1

  if (isNotAuthorized) {
    // Logout client and redirect to login
    context.app.$auth.logout()
    context.app.$auth.$storage.setUniversal(
      'redirect',
      context.app.router.currentRoute.fullPath
    )
    context.app.$auth.loginWith('auth0')
  }
}

// TODO: [BLK-667] We have to check if is possible to verify if the token is expired, setting expire in vue-auth
// TODO: [BLK-668] We hate to treat all erro apollo erros?

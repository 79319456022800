





















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'
import gql from 'graphql-tag'
import BlkFooter from '@/components/layout/BlkFooter.vue'
import BlkHeader from '@/components/layout/BlkHeader.vue'
import BlkDashboardBanner from '@/components/BlkDashboardBanner.vue'
import BlkLoadingScreen from '@/components/BlkLoadingScreen.vue'
import BlkModalForm from '~/components/landing/BlkModalForm.vue'

interface MeType {
  id: string
  isAccountComplete: boolean
}

@Component({
  name: 'DashboardLayout',
  components: {
    BlkHeader,
    BlkFooter,
    BlkLoadingScreen,
    BlkModalForm,
    BlkDashboardBanner
  },
  apollo: {
    me: {
      query: gql`
        {
          me {
            id
            isAccountComplete
            isVerifiedByAdmin
            firstAccess
            userAgreement
            groups
          }
        }
      `,
      skip() {
        return !this.$auth.user
      }
    }
  }
})
export default class extends Vue {
  @Prop({ type: String, default:'' }) contentClass!:string
  me: MeType = {
    id: '',
    isAccountComplete: false
  }

  hasCookie: string = 'false'

  setCookie() {
    localStorage.setItem('hasAcceptedCookies', 'true')
    this.hasCookie = 'true'
  }

  bannerClosed: boolean = false

  openModal: boolean = false

  @Mutation('login') mutationLogin: any

  mounted() {
    this.$loadingScreen.show = false
    if (localStorage.getItem('hasAcceptedCookies')) {
      this.hasCookie = 'true'
    }
  }

  openFormModal() {
    this.openModal = true
  }

  onCloseBanner() {
    this.bannerClosed = true
  }

  get showAlert() {
    return (
      this.$route.path !== '/' &&
      (!this.isAccountComplete ||
        !this.isEmailVerified ||
        !this.isAccountVerified) &&
      !this.bannerClosed &&
      this.$store.state.auth.loggedIn &&
      this.$route.path !== '/onboarding'
    )
  }

  get isAccountComplete() {
    return this.$store.state?.me?.isAccountComplete
  }

  get isEmailVerified() {
    // return this.$store.state.auth.user?.email_verified
    return true
  }

  get isAccountVerified() {
    return this.$store.state?.me?.isVerifiedByAdmin
  }
}

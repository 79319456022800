
































import { Component, Vue } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'

interface localTabsType {
  current: boolean
  past: boolean
}

@Component({
  name: 'BlkTabMenu'
})
export default class extends Vue {
  localTabs: localTabsType = { current: true, past: false }

  @Mutation('updateOffersTabMenu') mutationUpdateOffersTabMenu: any
  selectTab(tabName: string) {
    switch (tabName) {
      case 'current':
        this.localTabs.current = true
        this.localTabs.past = false
        break
      case 'past':
        this.localTabs.past = true
        this.localTabs.current = false
        break
    }
    this.mutationUpdateOffersTabMenu(this.localTabs)
  }

  get tabs() {
    return this.$store.state?.offersTabMenu || this.localTabs
  }
}

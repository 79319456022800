import Vue from 'vue'

export default ({ app }) => {
  const numeral = require('numeral')
  for (const locale of app.i18n.locales.filter(locale => locale != 'en-us')) {
    require(`numeral/locales/${locale}`)
  }

  numeral.locale(app.i18n.locale)

  Vue.prototype.$numeral = numeral
}



























































































































































import { Component, Vue } from 'vue-property-decorator'
import Logo from '~/components/Logo.vue'

@Component({
  name: 'BlkFooter',
  components: {
    Logo
  }
})
export default class extends Vue {}

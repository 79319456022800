import gql from 'graphql-tag'

export const strict = false

export const state = () => ({
  me: {
    address: {},
    userDocument: {}
  },
  loadingScreen: false,
  offersTabMenu: {
    current: true,
    past: false
  }
})

export const mutations = {
  loadingScreen(state: any, show: boolean) {
    state.loadingScreen = show
  },
  login(state: any, me: any) {
    state.me = me
  },
  isAccountComplete(state: any, isComplete: boolean) {
    state.me.isAccountComplete = isComplete
  },
  updateOffersTabMenu(state: any, offersTabMenu: any) {
    state.offersTabMenu = offersTabMenu
  }
}

export const getters = {
  me(state: any) {
    const me = state.me
    me.address = me.address || {}
    me.userDocument = me.userDocument || {}

    return me
  }
}

export const actions = {
  updateMe({ state }: { state: any }) {
    // @ts-ignore
    const client = this.app.apolloProvider.defaultClient
    const query = gql`
      {
        me {
          id
          address {
            addressData
            city
            complement
            country
            number
            state
            zipCode
          }
          files {
            fileName
            public
            s3Key
          }
          account {
            balance {
              cashBalance
              totalBalance
              bonusBalance
            }
          }
          bankAccountInfo {
            pixType
            pixKey
            bankCode
            bankAgency
            bankAccount
          }
          dateOfBirth
          education
          firstName
          gender
          groups
          hasForeignFiscalResidency
          isAccountComplete
          isEmailVerified
          isPoliticallyExposed
          isUsPerson
          isVerifiedByAdmin
          lastName
          maritalStatus
          mobileNumber
          monthlyOrAnnualIncome
          nationality
          occupation
          phoneNumber
          taxId
          userAgreement
          userDocument {
            issuedBy
            issuedDate
            kind
            number
          }
          workingAt
          suitability {
            question
            answer
            multiple
          }
          referralCode
        }
      }
    `
    return new Promise<any>((resolve, reject) => {
      client
        .query({ query })
        .then((res: any) => {
          state.me = res.data.me
        })
        .catch((e: any) => {
          if (
            !e.graphQLErrors.some((el: any) => el.message === 'Not authorized')
          ) {
            throw e
          }
        })
        .finally(() => {
          resolve(state.me)
        })
    })
  }
}








import { Component, Vue } from 'nuxt-property-decorator'
import BlkLoadingScreen from '@/components/BlkLoadingScreen.vue'

@Component({
  name: 'Clean',
  components: { BlkLoadingScreen }
})
export default class extends Vue {
  beforeCreate() {
    this.$loadingScreen.show = true
  }
}

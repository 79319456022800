<template>
  <default contentClass="medium-container" />
</template>

<script>
import Default from '@/layouts/default.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name:"mediumContainer",
  components:{Default}
})
</script>

<style lang="stylus">
.medium-container
  @apply mx-auto bg-white shadow-md
  height calc( 100vh - 76px )
  @media only screen and (min-width 768px)
      height calc( 100vh - 102px )
  max-width 32rem !important
</style>















































import { Component, Vue, Prop } from 'vue-property-decorator'
import gql from 'graphql-tag'

@Component({
  name: 'BlkDashboardBanner'
})
export default class extends Vue {
  @Prop({ default: false, type: Boolean }) showAlert!: boolean
  @Prop({ default: false, type: Boolean }) accountIncomplete!: boolean
  @Prop({ default: false, type: Boolean }) emailNotVerified!: boolean
  @Prop({ default: false, type: Boolean }) accountNotVerified!: boolean

  closeBanner() {
    this.$emit('closeBanner')
  }
}

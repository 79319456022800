





import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'BlkCta'
})
export default class extends Vue {
  @Prop({ default: 'Cadastre-se' }) ctaText!: string
}


















import { Component, Watch, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'BlkLoadingScreen'
})
export default class BlkLoadingScreen extends Vue {
  show: boolean = true

  @Watch('$store.state.loadingScreen')
  watchShow(value: boolean) {
    if (value) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }
}

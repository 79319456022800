




















































































































































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import Logo from '~/components/Logo.vue'
import BlkCta from '~/components/BlkCta.vue'
import BlkTabMenu from '~/components/BlkTabMenu.vue'

@Component({
  name: 'BlkHeader',
  components: {
    Logo,
    BlkCta,
    BlkTabMenu
  }
})
export default class extends Vue {
  @Prop({ default: false }) headerCta!: boolean

  get menu() {
    return [
      // {
      //   href: '/offers',
      //   target: '',
      //   text: this.$root.$t('BlkHeader.menu.invest')
      // },
      {
        href: '/learn-more',
        target: '',
        text: this.$root.$t('BlkHeader.menu.learnMore')
      },
      // {
      //   href: 'https://balko.gupy.io/',
      //   target: '_blank',
      //   external: true,
      //   text: this.$root.$t('BlkHeader.menu.work'),
      //   rel: 'noopener noreferrer'
      // },
      // {
      //   href: 'https://blog.balko.com.br/',
      //   target: '_blank',
      //   external: true,
      //   text: this.$root.$t('BlkHeader.menu.blog')
      // },
      {
        href: this.loginUrl,
        target: '',
        text: this.$root.$t('BlkHeader.menu.login')
      }
    ]
  }

  loggedIn = [
    {
      href: '/portfolio',
      target: '',
      text: this.$root.$tc('topNavBar.myInvestments')
    },
    {
      href: '/offers',
      target: '',
      text: this.$root.$t('BlkHeader.menu.invest')
    },
    {
      href: '/learn-more',
      target: '',
      text: this.$root.$t('BlkHeader.menu.learnMore')
    },
    {
      href: 'https://balko.gupy.io/',
      target: '_blank',
      external: true,
      text: this.$root.$t('BlkHeader.menu.work'),
      rel: 'noopener noreferrer'
    }
    // {
    //   href: 'https://blog.balko.com.br/',
    //   target: '_blank',
    //   external: true,
    //   text: this.$root.$t('BlkHeader.menu.blog')
    // }
  ]

  isNavOpen: boolean = false

  // @Emit('openFormModal')
  // openFormModal() {}

  async logout() {
    this.isNavOpen = false
    await this.$auth.logout()
    // @ts-ignore
    window.location.replace(
      // @ts-ignore
      `https://${this.$auth.strategy.options
        .domain as string}/v2/logout?returnTo=${location.origin}`
    )
  }

  get profilePath() {
    if (this.$store.state?.me?.isAccountComplete) {
      return '/profile'
    } else {
      return '/onboarding'
    }
  }

  get loginUrl() {
    return `/login${
      this.$route.fullPath != '/'
        ? `?to=${encodeURIComponent(this.$route.fullPath)}`
        : ''
    }`
  }
}

import Vue from 'vue'

interface LoadingScreen {
  show: boolean
}
declare module 'vue/types/vue' {
  interface Vue {
    $loadingScreen: LoadingScreen
  }
}

export default (
  { context, store }: { context: any; store: any },
  inject: any
) => {
  const loadingScreen = {
    get show() {
      return store.state.loadingScreen
    },
    set show(value) {
      store.commit('loadingScreen', value)
    }
  }
  inject('loadingScreen', loadingScreen)
}

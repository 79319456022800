import { InMemoryCache } from 'apollo-cache-inmemory'

export default function(context: any) {
  return {
    cache: new InMemoryCache(),
    httpEndpoint: process.server
      ? process.env.VUE_APP_GRAPHQL_ENDPOINT_SERVER
      : process.env.VUE_APP_GRAPHQL_ENDPOINT,
    getAuth: () => context.app.$auth.getToken('auth0'),
    apollo: {
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network'
        },
        query: {
          fetchPolicy: 'network-only'
        }
      }
    }
  }
}

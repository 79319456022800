





import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'Cleanest'
})
export default class extends Vue {}

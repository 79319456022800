export default function({ $axios, app, redirect }) {
  $axios.interceptors.response.use(
    function(response) {
      return response
    },
    function(error) {
      const code = parseInt(error.response && error.response.status)

      if ([401, 403].includes(code)) {
        app.$auth.reset()
        redirect(
          `/login${
            app.context.route.fullPath != '/'
              ? `?to=${encodeURIComponent(app.context.route.fullPath)}`
              : ''
          }`
        )
      }

      return Promise.reject(error)
    }
  )
}

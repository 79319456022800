export default async function({ store, redirect, app, route }) {
  if (app.$auth.loggedIn) {
    if (!store.me?.firstName) {
      await store.dispatch('updateMe')
    }

    if (!store.state.me.isAccountComplete && route.path !== '/onboarding') {
      redirect('/onboarding')
    }

    if (store.state.me.isAccountComplete && route.path == '/onboarding') {
      redirect('/profile')
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.showAlert && (_vm.accountIncomplete || _vm.emailNotVerified || _vm.accountNotVerified)
  )?_c('div',{staticClass:"pt-3 mx-md-5 mx-3 mt-120"},[_c('b-card',{class:_vm.accountIncomplete || _vm.emailNotVerified ? 'alert-card' : 'pending-card',attrs:{"title":_vm.$t(
        _vm.emailNotVerified
          ? 'topNavBar.alert.titleEmail'
          : _vm.accountIncomplete
          ? 'topNavBar.alert.titleAction'
          : 'topNavBar.alert.titleVerifying'
      )}},[_c('b-button',{staticClass:"close-alert close",on:{"click":function($event){return _vm.closeBanner()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_vm._v(" "),(_vm.emailNotVerified)?_c('b-card-text',{staticClass:"card-link"},[_vm._v(_vm._s(_vm.$t('topNavBar.alert.verifyEmail')))]):_vm._e(),_vm._v(" "),(!_vm.emailNotVerified && _vm.accountIncomplete)?_c('b-card-text',{staticClass:"text-black"},[_vm._v(_vm._s(_vm.$t('topNavBar.alert.content'))+"\n      "),_c('b-link',{staticClass:"text-blue",attrs:{"to":"/onboarding"}},[_vm._v(_vm._s(_vm.$t('topNavBar.alert.link')))])],1):_vm._e(),_vm._v(" "),(!_vm.emailNotVerified && !_vm.accountIncomplete && _vm.accountNotVerified)?_c('b-card-text',[_vm._v(_vm._s(_vm.$t('topNavBar.alert.contentVerifying')))]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }









































import { Component, Vue } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'
import gql from 'graphql-tag'
import BlkFooter from '@/components/layout/BlkFooter.vue'
import BlkLoadingScreen from '@/components/BlkLoadingScreen.vue'
import Logo from '~/components/Logo.vue'

interface MeType {
  id: string
  isAccountComplete: boolean
}

@Component({
  name: 'DashboardLayout',
  components: {
    BlkFooter,
    BlkLoadingScreen,
    Logo
  },
  apollo: {
    me: {
      query: gql`
        {
          me {
            id
            isAccountComplete
            isVerifiedByAdmin
            firstAccess
            userAgreement
            groups
          }
        }
      `,
      skip() {
        return !this.$auth.user
      }
    }
  }
})
export default class extends Vue {
  me: MeType = {
    id: '',
    isAccountComplete: false
  }

  hasCookie: string = 'false'

  setCookie() {
    localStorage.setItem('hasAcceptedCookies', 'true')
    this.hasCookie = 'true'
  }

  bannerClosed: boolean = false

  openModal: boolean = false

  @Mutation('login') mutationLogin: any

  mounted() {
    this.$loadingScreen.show = false
    if (localStorage.getItem('hasAcceptedCookies')) {
      this.hasCookie = 'true'
    }
  }

  openFormModal() {
    this.openModal = true
  }

  onCloseBanner() {
    this.bannerClosed = true
  }

  get isAccountComplete() {
    return this.$store.state?.me?.isAccountComplete
  }
}
